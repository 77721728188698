import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class FeaturedWork {
  constructor(block) {
    this.block = block

    //Elements
    this.headingElements = block.querySelectorAll('.main-title-row > *')
    this.link = block.querySelector('.featured-work__cta')
    this.secondHeading = block.querySelector('.featured-work__subheading')
    this.cards = block.querySelectorAll('.featured-work__card')

    //Animations
    this.animations()
  }

  animations() {
    if (this.headingElements) {
      this.headingElements.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25,
          ease: 'power2.out'
        })
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.link,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.link.getBoundingClientRect().right}px`,
        duration: 0.5
      })
    }

    if (this.secondHeading) {
      gsap.fromTo(
        this.secondHeading,
        {
          '--wipe-in': '0%'
        },
        {
          '--wipe-in': '125%',
          scrollTrigger: {
            trigger: this.secondHeading,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          duration: 0.5
        }
      )
    }

    if (this.cards) {
      gsap.from(this.cards, {
        scrollTrigger: {
          trigger: this.cards,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        duration: 1,
        stagger: 0.125,
        opacity: 0,
        y: '50px',
        ease: 'elastic.out(1, 0.5)'
      })
    }
  }
}

export default FeaturedWork
