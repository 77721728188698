class CursorEffect {
  constructor() {
    if (!document.querySelector('.cursor-effect')) {
      this.init()
    }
  }

  init() {
    this.cursor = document.createElement('div')
    this.cursor.classList.add('cursor-effect')
    document.addEventListener('mousemove', e => {
      this.cursor.style.left = `${e.clientX}px`
      this.cursor.style.top = `${e.clientY}px`
    })
    document.querySelector('body').insertAdjacentElement('beforeend', this.cursor)
  }
}

export default CursorEffect
