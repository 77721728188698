import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import Flickity from 'flickity'

class ClientsCarousel {
  constructor(block) {
    this.block = block

    //Elements
    this.heading = block.querySelector('.clients-carousel__heading')
    this.slider = block.querySelector('.clients-carousel__slider')
    this.slides = block.querySelectorAll('.clients-carousel__slide')

    //Flickity
    this.flickityInit()

    //Animations
    this.animations()
  }

  flickityInit() {
    if (this.slider) {
      this.flkty = new Flickity(this.slider, {
        pauseAutoPlayOnHover: false,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false
        // autoPlay: 5000
      })

      const tickerSpeed = 0.25
      let isPaused = false

      const update = () => {
        if (isPaused) return
        if (this.flkty.slides) {
          this.flkty.x = (this.flkty.x - tickerSpeed) % this.flkty.slideableWidth
          this.flkty.selectedIndex = this.flkty.dragEndRestingSelect()
          this.flkty.updateSelectedSlide()
          this.flkty.settle(this.flkty.x)
        }
        window.requestAnimationFrame(update)
      }

      const pause = () => {
        isPaused = true
      }

      const play = () => {
        if (isPaused) {
          isPaused = false
          window.requestAnimationFrame(update)
        }
      }

      this.slider.addEventListener('mouseenter', pause, false)
      this.slider.addEventListener('focusin', pause, false)
      this.slider.addEventListener('mouseleave', play, false)
      this.slider.addEventListener('focusout', play, false)

      update()
    }
  }

  animations() {
    if (this.heading) {
      gsap.fromTo(
        this.heading,
        {
          '--wipe-in': '0%'
        },
        {
          '--wipe-in': '125%',
          scrollTrigger: {
            trigger: this.heading,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          duration: 0.5
        }
      )
    }

    if (this.slides) {
      gsap.from(this.slides, {
        scrollTrigger: {
          trigger: this.slides,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        opacity: 0,
        duration: 0.5,
        stagger: 0.25
      })
    }
  }
}

export default ClientsCarousel
