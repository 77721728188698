import Flickity from 'flickity';
import 'flickity-fade';

class ImageFaderCarousel {
  constructor(block) {
    this.block = block;

    this.slider = this.block.querySelector('.image-fader-carousel__slider');
    this.slides = this.block.querySelectorAll('.image-fader-carousel__cell');

    if (this.slider && this.slides.length > 1) {
      this.flickityInit();
    }
  }

  flickityInit() {
    this.flkty = new Flickity(this.slider, {
      pauseAutoPlayOnHover: false,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 6000,
      fade: true,
    });
  }
}


export default ImageFaderCarousel;