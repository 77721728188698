class Header {
  constructor(header) {
    this.header = header
    this.body = document.querySelector('body')
    this.toggleClass = 'header--fixed'
    this.menuButton = header.querySelector('.navbar-toggler')
    this.headerInit()
  }

  headerInit() {
    //Set fixed on scroll
    window.addEventListener('scroll', () => {
      const currentScroll = window.scrollY
      if (currentScroll > 100) {
        this.header.classList.add(this.toggleClass)
      } else {
        this.header.classList.remove(this.toggleClass)
      }
    })

    //Nav menu button functionality
    this.menuButton.addEventListener('click', () => {
      this.menuButton.classList.toggle('navbar-toggler--open')
    })

    //Set header height custom property
    this.body.style.setProperty('--header-height', `${this.header.offsetHeight}px`)

    window.addEventListener('scroll', () => {
      this.body.style.setProperty('--header-height', `${this.header.offsetHeight}px`)
    })

    window.addEventListener('resize', () => {
      this.body.style.setProperty('--header-height', `${this.header.offsetHeight}px`)
    })
  }
}

export default Header
