class KeyStats {
  constructor(element) {
    this.block = element;
    this.stats = this.block.querySelectorAll('.key-stats__item');
    this.observer = null;
    this.init();
  }

  init() {
    if (!this.stats.length) return;
    this.createObserver();
  }

  createObserver() {
    const options = {
      root: null,
      threshold: 0.3
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.animateNumbersWithDelay();
          this.observer.unobserve(entry.target);
        }
      });
    }, options);

    this.stats.forEach(stat => this.observer.observe(stat));
  }

  animateNumbersWithDelay() {
    const totalDuration = 4000;
    const individualDuration = totalDuration / this.stats.length;

    this.stats.forEach((statElement, index) => {
      setTimeout(() => {
        this.animateNumber(statElement, individualDuration);
      }, index * 1000);
    });
  }

  easeInQuad(t) {
    return t * t;
  }

  animateNumber(statElement, duration) {
    const targetNumber = parseInt(statElement.dataset.target, 10);
    const numberElement = statElement.querySelector('.key-stats__value');
    const prefixElement = statElement.querySelector('.key-stats__prefix');
    const suffixElement = statElement.querySelector('.key-stats__suffix');

    let startTime = null;
    const startNumber = 0;

    if (prefixElement) {
      prefixElement.textContent = statElement.dataset.prefix || '';
    }
    if (suffixElement) {
      suffixElement.textContent = statElement.dataset.suffix || '';
    }

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;

      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);


      const easedProgress = this.easeInQuad(progress);
      const currentNumber = Math.floor(startNumber + easedProgress * targetNumber);

      numberElement.textContent = currentNumber;

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        numberElement.textContent = targetNumber;
      }
    };

    requestAnimationFrame(animate);
  }
}

export default KeyStats;


