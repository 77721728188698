import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class SingleTextColumn {
  constructor(block) {
    this.block = block
    this.elements = block.querySelectorAll('.single-text-column__eyebrow, .single-text-column__heading, .single-text-column__text > p')
    this.link = block.querySelector('.single-text-column__cta')

    //Animations
    this.animations()
  }

  animations() {
    gsap.from(this.block, {
      scrollTrigger: {
        trigger: this.block,
        start: 'top 100%',
        toggleActions: 'play none none reset'
      },
      filter: 'grayscale(75%)',
      duration: 0.5,
      delay: 0.25
    })

    gsap.fromTo(
      this.block,
      {
        '--background-opacity': 0
      },
      {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        '--background-opacity': 0.125,
        duration: 0.5,
        delay: 0.25
      }
    )

    if (this.elements) {
      this.elements.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25
        })
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.link,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.link.getBoundingClientRect().right}px`,
        duration: 0.5
      })
    }
  }
}

export default SingleTextColumn
