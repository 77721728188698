import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class OurWork {
  constructor(block) {
    this.block = block

    //Elements
    this.projects = block.querySelectorAll('.our-work__project')
    this.link = block.querySelector('.our-work__cta')

    this.animations()
    // this.setProjectsLinkPosition()
  }

  animations() {
    if (this.projects) {
      this.projects.forEach(project => {
        gsap.from(project.querySelector('.our-work__project-background'), {
          scrollTrigger: {
            trigger: project,
            start: 'top 90%',
            toggleActions: 'play none none reverse'
          },
          filter: 'grayscale(100%) blur(5px)',
          scale: 1.1,
          duration: 0.5
        })
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.link,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.link.getBoundingClientRect().right}px`,
        duration: 0.5
      })
    }
  }

  // setProjectsLinkPosition() {
  //   this.projects.forEach(project => {
  //     const link = project.querySelector('.our-work__project-cta')
  //     link.style.setProperty('--rect-right', `-${link.getBoundingClientRect().right}px`)
  //   })
  // }
}

export default OurWork
