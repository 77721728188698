import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class InnerBanner {
  constructor(block) {
    this.block = block

    //Elements
    this.background = block.querySelector('.single-text-section__bg-image')
    this.elements = block.querySelectorAll('.inner-banner-wrapper__main-title, .inner-banner-wrapper__sub-title')
    this.link = block.querySelector('.cta-btn')

    //Animations
    this.animations()
  }

  animations() {
    gsap.from(this.block, {
      scrollTrigger: {
        trigger: this.block,
        start: 'top 100%',
        toggleActions: 'play none none reset'
      },
      filter: 'grayscale(75%)',
      duration: 0.5,
      delay: 0.25
    })

    if (this.background) {
      gsap.from(this.background, {
        scrollTrigger: {
          trigger: this.background,
          start: 'top 90%',
          toggleActions: 'play none none reset'
        },
        opacity: 0,
        duration: 0.5,
        delay: 0.25
      })
    }

    if (this.elements) {
      this.elements.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25
        })
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.link,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.link.getBoundingClientRect().right}px`,
        duration: 0.5
      })
    }
  }
}

export default InnerBanner
