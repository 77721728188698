import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class ServicesDetails {
  constructor(block) {
    this.block = block
    this.header = document.querySelector('header')

    //Elements
    this.navWrapper = block.querySelector('.services-details__nav-wrapper')
    this.navItems = block.querySelectorAll('.services-details__nav-item')
    this.mobileNav = block.querySelector('.services-details__mobile-nav')
    this.services = block.querySelectorAll('.services-details__service')

    //Sticky nav
    this.stickyNav()

    //Animations
    this.animations()

    this.handleInitialHash();
  }

  stickyNav() {
    if (this.navWrapper) {
      window.addEventListener('scroll', () => {
        const headerHeight = this.header.offsetHeight

        if (this.navWrapper.getBoundingClientRect().top <= headerHeight) {
          this.navWrapper.classList.add('services-details__nav-wrapper--stuck')
        } else {
          this.navWrapper.classList.remove('services-details__nav-wrapper--stuck')
        }
      })
    }

    if (this.navItems) {
      this.navItems.forEach(item => {
        const itemLink = item.querySelector('a')
        itemLink.addEventListener('click', e => {
          e.preventDefault()
          const target = this.block.querySelector(`.services-details__service[data-service="${itemLink.dataset.service}"]`)
          window.scrollTo(0, target.offsetTop - this.header.offsetHeight - this.navWrapper.offsetHeight - 32)
        })
      })
    }

    if (this.mobileNav) {
      this.mobileNav.addEventListener('change', () => {
        const target = this.block.querySelector(`.services-details__service[data-service="${this.mobileNav.value}"]`)
        window.scrollTo(0, target.offsetTop - this.header.offsetHeight - this.navWrapper.offsetHeight)
      })
    }

    // Handle footer links with hashes
    document.querySelectorAll('.site-footer__services a').forEach(link => {
      link.addEventListener('click', e => {
        const href = link.getAttribute('href');
        if (href.startsWith('/services/#')) {
          e.preventDefault();
          const targetId = href.split('#')[1];
          this.scrollToService(targetId);
          history.replaceState(null, null, `/services/#${targetId}`);
        }
      });
    });

    if (this.services) {
      this.services.forEach(service => {
        ScrollTrigger.create({
          trigger: service,
          start: 'top 50%',
          end: 'bottom 50%',
          onToggle: ({ isActive }) => {
            this.navItems.forEach(item => {
              const itemLink = item.querySelector('a')
              if (itemLink.dataset.service == service.dataset.service) {
                if (isActive) {
                  item.classList.add('services-details__nav-item--active')
                } else {
                  item.classList.remove('services-details__nav-item--active')
                }
              } else {
                item.classList.remove('services-details__nav-item--active')
              }
            })
            if (isActive) {
              this.mobileNav.value = service.dataset.service
            }
          }
        })
      })
    }
  }

  scrollToService(serviceSlug) {
    const target = document.getElementById(serviceSlug);
    if (target) {
      window.scrollTo({
        top: target.offsetTop - this.header.offsetHeight - this.navWrapper.offsetHeight - 32,
        behavior: 'smooth'
      });
    }
  }

  handleInitialHash() {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      setTimeout(() => this.scrollToService(hash), 300);
    }
  }

  animations() {
    if (this.services) {
      this.services.forEach(service => {
        const projects = service.querySelectorAll('.project-card')
        const content = service.querySelectorAll('.services-details__service-text > *')

        if (projects) {
          gsap.from(projects, {
            scrollTrigger: {
              trigger: projects,
              start: 'top 90%',
              toggleActions: 'play none none reverse'
            },
            filter: 'blur(50px)',
            scale: 0.875,
            opacity: 0,
            duration: 0.5,
            delay: 0.25,
            stagger: 0.125
          })
        }

        if (content) {
          gsap.from(content, {
            scrollTrigger: {
              trigger: content,
              start: 'top 90%',
              toggleActions: 'play none none reverse'
            },
            y: '1rem',
            opacity: 0,
            duration: 0.5,
            delay: 0.25,
            stagger: 0.125
          })
        }
      })
    }
  }
}

export default ServicesDetails
