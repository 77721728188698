import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class ApproachDetails {
  constructor(block) {
    this.block = block

    //Elements
    this.text = block.querySelectorAll('.approach-details__inner > *')
    this.steps = block.querySelectorAll('.approach-details__step')

    //Animations
    this.animations()
  }

  animations() {
    // gsap.from(this.block, {
    //   scrollTrigger: {
    //     trigger: this.block,
    //     start: 'top 100%',
    //     toggleActions: 'play none none reset'
    //   },
    //   filter: 'grayscale(75%)',
    //   duration: 0.5,
    //   delay: 0.25
    // })

    gsap.fromTo(
      this.block,
      {
        '--background-opacity': 0
      },
      {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 50%',
          toggleActions: 'play none none reverse'
        },
        '--background-opacity': 0.25,
        duration: 0.5,
        delay: 0.25
      }
    )

    if (this.text) {
      this.text.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25
        })
      })
    }

    if (this.steps) {
      this.steps.forEach(step => {
        const textLeft = step.querySelector('.approach-details__step-left')
        const textRight = step.querySelector('.approach-details__step-right')
        const content = step.querySelectorAll('.approach-details__step-inner > *')

        if (textLeft) {
          gsap.fromTo(
            textLeft,
            { x: '-100%', opacity: 0 },
            {
              x: '0%',
              opacity: 1,
              duration: 0.5,
              scrollTrigger: {
                trigger: textLeft,
                start: 'top 90%',
                toggleActions: 'play none none reverse',

              },
            }
          );

          gsap.to(textLeft, {
            opacity: 0,
            immediateRender: false,
            scrollTrigger: {
              trigger: textLeft,
              start: 'top 10%',
              end: 'top 0%',
              // scrub: true,
              toggleActions: 'play none none reverse',
            },
          });
        }



        if (textRight) {
          gsap.fromTo(
            textRight,
            { x: '100%', opacity: 0 },
            {
              x: '0%',
              opacity: 1,
              duration: 0.5,
              scrollTrigger: {
                trigger: textRight,
                start: 'top 90%',
                toggleActions: 'play none none reverse',
              },
            }
          );

          gsap.to(textRight, {
            opacity: 0,
            immediateRender: false,
            scrollTrigger: {
              trigger: textRight,
              start: 'top 10%',
              end: 'top 0%',
              // scrub: true,
              toggleActions: 'play none none reverse',
              // markers: true, 
            },
          });
        }






        // if (textLeft) {
        //   gsap.fromTo(
        //     textLeft,
        //     {
        //       x: '-100%',
        //       opacity: 0
        //     },
        //     {
        //       x: '0%',
        //       opacity: 1,
        //       scrollTrigger: {
        //         trigger: textLeft,
        //         start: 'top 100%',
        //         toggleActions: 'play none none reset'
        //       },
        //       duration: 0.5,
        //       delay: 0.25
        //     }
        //   )
        // }

        // if (textRight) {
        //   gsap.fromTo(
        //     textRight,
        //     {
        //       x: '100%',
        //       opacity: 0
        //     },
        //     {
        //       x: '0%',
        //       opacity: 1,
        //       scrollTrigger: {
        //         trigger: textRight,
        //         start: 'top 100%',
        //         toggleActions: 'play none none reset'
        //       },
        //       duration: 0.5,
        //       delay: 0.25
        //     }
        //   )
        // }

        gsap.from(content, {
          scrollTrigger: {
            trigger: content,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25,
          stagger: 0.125
        })
      })
    }
  }
}

export default ApproachDetails



